import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { modularOutcomeTypeQuestion } from "constants/questionTypes";
import {words} from '../translations'
import { use } from "i18next";
// ... styled-components code ...

const SliderOutcome = ({
  id,
  resetResponsesOnChange,
  question,
  saveUpdatedAnswers,
  currentQuestionDet,
  questionSequenceMap,
  language
}) => {
  const [sliderValue, setSliderValue] = useState(5);
  const [isDragging, setIsDragging] = useState(false);
  const [clickedVal, setClickedVal] = useState(false);
  const [sliderScale, setSliderScale] = useState(question.scale);
  const [sliderWords, setSliderWords] = useState(words);

  useEffect(() => {
    if (question.scale) {
      setSliderScale(question.scale);
    }
  }, [question]);
  

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const track = document.getElementById("slider-track");
      const trackRect = track.getBoundingClientRect();
      let newValue = ((e.clientX - trackRect.left) / trackRect.width) * 10;

      newValue = Math.min(Math.max(newValue, 0), 10);

      setSliderValue(newValue);
      saveValue(newValue);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setSliderValue(Math.round(sliderValue));
      saveValue(Math.round(sliderValue));
    };

    const handleTouchMove = (e) => {
      if (!isDragging) return;

      const track = document.getElementById("slider-track");
      const trackRect = track.getBoundingClientRect();
      let newValue =
        ((e.touches[0].clientX - trackRect.left) / trackRect.width) * 10;

      newValue = Math.min(Math.max(newValue, 0), 10);

      setSliderValue(newValue);
      saveValue(newValue);
    };

    const handleTouchEnd = () => {
      let newValue = Math.round(sliderValue);
      setIsDragging(false);
      setSliderValue(newValue);
      saveValue(newValue);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDragging, sliderValue]);

  useEffect(() => {
    if (currentQuestionDet?.response) {
      setSliderValue(currentQuestionDet?.response?.response);
      setClickedVal(true);
    } else {
      setSliderValue(5);
      setClickedVal(false);
    }
  }, [currentQuestionDet]);

  const saveValue = (newValue) => {
    setClickedVal(true);
    //
    SaveResponse(newValue);
  };

  const handleClick = (e) => {
    const track = document.getElementById("slider-track");
    // const track = document.getElementById(id);
    const rect = track.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const newValue = Math.round((x / rect.width) * 10);
    setSliderValue(newValue);
    setClickedVal(true);
    //
    SaveResponse(newValue);
  };

  // save the response
  function SaveResponse(newValue) {
    // reset associated questions
    // check if value changed
    if (currentQuestionDet?.response &&
      currentQuestionDet?.response?.response !== newValue) {
      if (currentQuestionDet?.resetOnChange) {
        // resetResponsesOnChange
        if (resetResponsesOnChange[currentQuestionDet?.id]) {
          // reset the responses to each of the questions
          // console.log(resetResponsesOnChange[currentQuestionDet?.id]);
          resetResponsesOnChange[currentQuestionDet?.id].map((sq) => {
            // console.log(questionSequenceMap[sq]);
            questionSequenceMap[sq].response = false;
            return null;
          });
        }
      }
    }

    const QuesResp = {
      order: currentQuestionDet?.id,
      clicked: true,
      s: currentQuestionDet?.s,
      q: currentQuestionDet?.q,
      id: currentQuestionDet?.id,
      ques_order: currentQuestionDet?.id,
      scale: question?.scale,
      question: question?.question,
      response: newValue,
      ques_sort_order: currentQuestionDet?.id,
    };
    saveUpdatedAnswers(
      currentQuestionDet.id,
      QuesResp,
      modularOutcomeTypeQuestion
    );
  }

  // Add a function to interpolate between two colors
  const lerpColor = (color1, color2, t) => {
    const r = color1.r + t * (color2.r - color1.r);
    const g = color1.g + t * (color2.g - color1.g);
    const b = color1.b + t * (color2.b - color1.b);
    return { r, g, b };
  };

  // Add a function to calculate the thumb color
  const getThumbColor = () => {
    const color1 = { r: 248, g: 168, b: 176 };
    const color2 = { r: 254, g: 205, b: 141 };
    const color3 = { r: 149, g: 230, b: 210 };
    const t = sliderValue / 10;

    if (t < 0.5232) {
      const newT = t / 0.5232;
      const interpolatedColor = lerpColor(color1, color2, newT);
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
    } else {
      const newT = (t - 0.5232) / (1 - 0.5232);
      const interpolatedColor = lerpColor(color2, color3, newT);
      return `rgb(${interpolatedColor.r}, ${interpolatedColor.g}, ${interpolatedColor.b})`;
    }
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const thumbPosition = {
    left: `calc(${(sliderValue / 10) * 100}% - 10px )`,
  };

  const dotCount = 11;
  const dots = Array.from({ length: dotCount }, (_, index) => (
    <Dot
      key={index}
      style={{ left: `${(index / (dotCount - 1)) * 100}%` }}
      id={`dot_${(index / (dotCount - 1)) * 10}_${id}`}
    />
  ));

  

  const get_slider_words = (text) => {
    console.log(text)
    if (words[text]) {
      return words[text][language];
    }

    return text;

  }

  return (
    <Container>
      <TrackWrapper
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <Track id={"slider-track"}>
          <DotsContainer id={`dots_${id}`}>{dots}</DotsContainer>
          <Thumb
            style={{
              ...thumbPosition,
              backgroundColor: getThumbColor(),
            }}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
            id={`thumb_${id}`}
          >
            <Tooltip clicked={clickedVal} id={`tip_${id}`}>
              {Math.round(sliderValue)}
              <Triangle />
            </Tooltip>
          </Thumb>
        </Track>
      </TrackWrapper>

      <ScaleLabelsContainer>
        <ScaleLabel>
          {sliderScale.length > 0 ? get_slider_words(sliderScale[0]) : "Strongly Disagree"}
        </ScaleLabel>
        <ScaleLabel>
          {sliderScale.length > 2 ?get_slider_words(sliderScale[1]) : words["Neutral"][language]}
        </ScaleLabel>
        <ScaleLabel>
          {sliderScale.length > 1
            ? get_slider_words(sliderScale[sliderScale.length - 1])
            : "Strongly Agree"}
        </ScaleLabel>
      </ScaleLabelsContainer>
    </Container>
  );
};

export default SliderOutcome;

const Container = styled.div`
  width: 100%;
  padding-top: 70px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const ScaleLabel = styled.span`
  font-size: 14px;
`;

const SliderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ScaleLabelsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const TrackWrapper = styled.div`
  position: relative;
  height: calc(10px + 10px); // 5px above and below the track area
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const Track = styled.div`
  width: 100%;
  height: 6px;
  background: linear-gradient(
    to right,
    #f8a8b0 0%,
    #fecd8d 52.32%,
    #95e6d2 100%
  );
  position: relative;
  border-radius: 5px;
  cursor: pointer;
`;

const Thumb = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  border: 4px solid white;
  box-shadow: 0px 8px 20px rgba(63, 134, 254, 0.08);
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #666d79;
  border-radius: 8px;
  width: 33px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.clicked ? "#FFFFFF" : "#D6DBDF")};
  font-size: 14px;
  font-weight: ${(props) => (props.clicked ? "700" : " 400")};
  margin-bottom: 8px; // 8px above the track
`;

const Triangle = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0;
  border-color: #666d79 transparent transparent;
`;

const DotsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  display: flex;
  justify-content: space-between;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
`;
